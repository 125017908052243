import * as companyType from "constants/companyTypes";
import * as userTypes from "constants/userTypes";
import * as R from "ramda";

export const rolesWithViewPermissions = ["SUPERUSER", "OPERATOR", "ANALYZER"];
export const rolesWithManagePermissions = ["SUPERUSER", "OPERATOR"];

export const userHasRoleForCompany = (user, roles) => {
  const company = user.currentCompany;
  return company ? R.includes(R.prop("companyRole", company), roles) : false;
};

export const userIsAdvertiser = (user) =>
  !!(user.currentCompany?.type === companyType.ADVERTISER);
export const userIsPublisher = (user) =>
  !!(user.currentCompany?.type === companyType.PUBLISHER);
export const userIsEmployee = (user) =>
  !!user.isEmployee || !!user.impersonator?.isEmployee;
export const advertiserCanAccessRecruitTab = (user) =>
  userIsAdvertiser(user) &&
  (!user.currentCompany.features?.has("HIDE_RECRUIT_TAB") ||
    userIsEmployee(user));
export const publisherCanAccessProfile = (user) =>
  userIsPublisher(user) &&
  (userHasRoleForCompany(user, rolesWithViewPermissions) ||
    userIsEmployee(user));

export const selectUserType = (user) => {
  if (!user) return;

  const isAdvertiser = userIsAdvertiser(user);
  const isPublisher = userIsPublisher(user);
  const isEmployee = userIsEmployee(user);

  if (isAdvertiser && !isEmployee) return userTypes.CLIENT_AS_ADVERTISER;
  if (isPublisher && !isEmployee) return userTypes.CLIENT_AS_PUBLISHER;
  if (isAdvertiser && isEmployee) return userTypes.EMPLOYEE_AS_ADVERTISER;
  if (isPublisher && isEmployee) return userTypes.EMPLOYEE_AS_PUBLISHER;
  if (isEmployee) return userTypes.EMPLOYEE_AS_SELF;
  return userTypes.CLIENT_AS_SELF;
};

export const userCanMakeOffer = (user) => {
  if (!user) return false;

  const userType = selectUserType(user);

  const userIsAdvertiser = userType === userTypes.CLIENT_AS_ADVERTISER;
  const hasRoles = userHasRoleForCompany(user, rolesWithManagePermissions);

  const userIsEmployeeAsAdvertiser =
    userType === userTypes.EMPLOYEE_AS_ADVERTISER;

  const userCompanies =
    (user.impersonator ? user.impersonator.companies : user.companies) || [];

  const hasAdvertiserInList =
    userCompanies.find((c) => c.id === user.currentCompany.id) !== undefined;

  return (
    (userIsAdvertiser && hasRoles) ||
    (userIsEmployeeAsAdvertiser && hasAdvertiserInList)
  );
};

export const hasAuthorizedRole = (user, allowedUserTypes) => {
  const userType = selectUserType(user);
  const userAllowedToSeeRoute = R.includes(userType)(allowedUserTypes);

  return userAllowedToSeeRoute;
};
