import { useQuery } from "@tanstack/react-query";
import { UserContext } from "context/ViewContext/UserContext";
import cookies from "js-cookie";
import * as R from "ramda";
import { authenticatedFetch } from "utils/clients";
import { getUserId } from "utils/platformAuth";
import { useProviderInitializedContext } from "./useProviderInitializedContext";

export const selectActingCompany = (user) => {
  const oldNavCompanyId = parseInt(cookies.get("company_id"), 10);
  const newNavCompanyId = user?.currentCompany?.id;

  const companyId = oldNavCompanyId || newNavCompanyId;

  if (user && !R.isEmpty(user.companies)) {
    return (
      R.pipe(
        R.propOr([], "companies"),
        R.find(R.propEq("id", companyId))
      )(user) || {}
    );
  }

  return {};
};

export const standardizeUser = (legacyUser) => {
  return {
    ...legacyUser,
    currentCompany: selectActingCompany(legacyUser),
  };
};

export const useUser = () => {
  return useProviderInitializedContext(UserContext);
};

export const useUserQuery = (userId) => {
  return useQuery(["user", userId], () => getUser(userId));
};

export const getUser = async (userId) => {
  try {
    if (!userId) {
      userId = await getUserId();
    }

    const userFetch = await authenticatedFetch(
      `${process.env.REACT_APP_MEMBER_URL}/affapi/oauth/user/${userId}?includeActiveOnlyForBackwardsCompatibility=false`,
      {},
      false
    );

    const userData = await userFetch.json();

    return {
      userId,
      ...userData,
    };
  } catch {
    return {};
  }
};
