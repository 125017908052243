import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { ErrorPage } from "@cjdev-internal/visual-stack-x/ErrorPage";
import { MDIcon } from "@cjdev-internal/visual-stack-x/components/MDIcon";
import { Panel } from "@cjdev-internal/visual-stack-x/components/Panel";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { redirectToMemberUrl } from "App";
import { Layout } from "components/Layout";
import { Spinner } from "components/Spinner";
import {
  useCompany,
  useDistinctPropertyValues,
  useFavoritedPublishers,
  useIntl,
  useParams,
  usePublisherProfile,
  useReactRouterUtil,
  useRoutePath,
  useUser,
} from "hooks";
import { useNav2022 } from "hooks/useNav2022";
import React from "react";
import {
  advertiserCanAccessRecruitTab,
  publisherCanAccessProfile,
  userIsAdvertiser as userCanSeeFavorites,
  userIsAdvertiser,
  userIsEmployee,
} from "utils/authorizationUtils";
import messages from "./messages";
import { PublisherDetails } from "./PublisherDetails";
import { PublisherTabs } from "./PublisherTabs";
import { OVERVIEW_TAB_INDEX } from "./PublisherTabs/tabIndex";
import "./styles.css";

export const ProfileView = ({
  onCancel,
  tabIndex = 0,
  searchTerms,
  reactRouterHook = useReactRouterUtil,
  publisherId,
}) => {
  const intl = useIntl();
  const { routeHistory } = useRoutePath();
  const { user } = useUser();
  const company = useCompany();
  const isAdvertiserOrEmployee = userIsAdvertiser(user) || userIsEmployee(user);
  const { nav2022 } = useNav2022();

  const userAllowedToSeePage =
    userIsEmployee(user) ||
    advertiserCanAccessRecruitTab(user) ||
    (publisherCanAccessProfile(user) && company.id === parseInt(publisherId));

  const {
    data: favoritePublishers,
    isLoading: isLoadingFavoritePublishers,
    postFavorite,
    postUnfavorite,
  } = useFavoritedPublishers();

  const { pushWithQuery } = reactRouterHook();
  const {
    data: distinctPropertyValues,
    loading: isLoadingDistinctPropertyValues,
  } = useDistinctPropertyValues(publisherId, userAllowedToSeePage);

  const { data: publisher, isLoading: isLoadingPublisher } =
    usePublisherProfile(publisherId, userAllowedToSeePage);

  const isPageLoading =
    // Check for userAllowedToSeePage here so that
    // if user doesn't have access to publisher's profile,
    // the profile query won't run yet still allow the page to render 403
    (userAllowedToSeePage && isLoadingPublisher) ||
    isLoadingDistinctPropertyValues ||
    isLoadingFavoritePublishers;

  const allowToSeeFavorites = userCanSeeFavorites(user);

  const content = !userAllowedToSeePage ? (
    <ErrorPage errorCode={403} onClickBack={redirectToMemberUrl} />
  ) : (
    <Box gap="xl" padding="xl" className="profile-container" justify="center">
      {userIsAdvertiser(user) && (
        <Box align="start">
          <Button
            id="back-button"
            type="tertiary"
            onClick={() => {
              pushWithQuery(routeHistory, { publisherId });
            }}
          >
            <Row gap="medium" align="center">
              <MDIcon icon="arrow-left" size={24} />
              {intl.formatMessage(messages.backToResults)}
            </Row>
          </Button>
        </Box>
      )}
      <Panel>
        <PublisherDetails
          publisher={publisher}
          favoritePublishers={favoritePublishers}
          postFavorite={postFavorite}
          postUnfavorite={postUnfavorite}
          shouldRenderFavorite={allowToSeeFavorites}
          isAdvertiserOrEmployee={isAdvertiserOrEmployee}
        />
        <PublisherTabs
          searchTerms={searchTerms}
          publisher={publisher}
          tabIndex={tabIndex}
          distinctPropertyValues={distinctPropertyValues}
        />
      </Panel>
    </Box>
  );

  const loadingOrContent = isPageLoading ? (
    <Box padding="xl" align="center">
      <Spinner />
    </Box>
  ) : (
    content
  );

  return nav2022 ? (
    <Box>{loadingOrContent}</Box>
  ) : (
    <Layout onCancel={onCancel}>{loadingOrContent}</Layout>
  );
};

export const Profile = ({
  fetch,
  onCancel,
  tabIndex = OVERVIEW_TAB_INDEX,
  ...restProps
}) => {
  const { params } = useParams();
  const { id: publisherId } = params;
  return (
    <ProfileView
      onCancel={onCancel}
      tabIndex={tabIndex}
      publisherId={publisherId}
      {...restProps}
    />
  );
};
